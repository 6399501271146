exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-authors-illustrators-jsx": () => import("./../../../src/pages/authors-illustrators.jsx" /* webpackChunkName: "component---src-pages-authors-illustrators-jsx" */),
  "component---src-pages-books-jsx": () => import("./../../../src/pages/books.jsx" /* webpackChunkName: "component---src-pages-books-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-pages-typology-jsx": () => import("./../../../src/pages/typology.jsx" /* webpackChunkName: "component---src-pages-typology-jsx" */),
  "component---src-templates-book-template-jsx": () => import("./../../../src/templates/BookTemplate.jsx" /* webpackChunkName: "component---src-templates-book-template-jsx" */),
  "component---src-templates-contributor-template-jsx": () => import("./../../../src/templates/ContributorTemplate.jsx" /* webpackChunkName: "component---src-templates-contributor-template-jsx" */),
  "component---src-templates-tag-category-template-jsx": () => import("./../../../src/templates/TagCategoryTemplate.jsx" /* webpackChunkName: "component---src-templates-tag-category-template-jsx" */),
  "component---src-templates-tag-template-jsx": () => import("./../../../src/templates/TagTemplate.jsx" /* webpackChunkName: "component---src-templates-tag-template-jsx" */)
}

